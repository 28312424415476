import React from 'react'
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
const Footer = () => {
    return (
        <div className=""
            style={{
                width: "100%",
                height: "200px",
                backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/canbebe-89ff0.appspot.com/o/Rectangle.webp?alt=media&token=491fe601-20d9-43dc-9821-93d637cf26ad)',
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                position: 'relative'
            }}

        >
            <div style={{ display: 'flex', alignItems: "center", gap: "20px", color: "#5E2676", position: "absolute", bottom: '30px', left: '50%', transform: 'translateX(-50%)', fontSize: "35px" }}>
                <div>
                    <a href="https://www.instagram.com/canbebealgerie/" target="_blank" style={{ color: "#5E2676" }}>
                        <FaInstagram />
                    </a>

                </div>
                <div>
                    <a href="https://www.facebook.com/CanbebeAlgerie" target="_blank" style={{ color: "#5E2676" }}>
                        <FaFacebookF />
                    </a>
                </div>
                <div>
                    <a href="https://www.youtube.com/@CanbebeAlgerie" target="_blank" style={{ color: "#5E2676" }}>
                        <FiYoutube />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer