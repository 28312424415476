import React from 'react'
import styles from "./PrivacyPolicy.module.css"

const PrivacyPolicy = () => {
    return (
        <div className={styles.PrivacyPolicy}>
            <h1>Politique De Confidentialité</h1>
            <section>
                <h2>Introduction</h2>
                <p><span>Can Hygiène SPA</span>, propriétaire de l’application <span>“Canbebe”</span>, s’engage à protéger et préserver la vie privée et la sécurité des utilisateurs de l’application. Toutes les données personnelles communiquées seront traitées suivant la présente charte de protection des données personnelles (la « <span>Charte</span> »), et conformément à la loi n°18-07 du 18 juin 2018 relative à la protection des personnes physiques dans le traitement des données à caractère personnel (« <span>Loi n°18-07</span> »). L’utilisateur s’engage à prendre connaissance de la Charte.</p>
            </section>
            <section>
                <h2>Collecte des renseignements personnels</h2>
                <p>La collecte de données personnelles porte sur les renseignements suivants :</p>
                <ul>
                    <li>Nom de l’utilisateur</li>
                    <li>Prénom de l’utilisateur</li>
                    <li>Date de naissance de l’utilisateur</li>
                    <li>Photo de profil de l’utilisateur</li>
                    <li>Prénom(s) du/des bébé(s)</li>
                    <li>Date(s) de naissance du/des bébé(s)</li>
                    <li>Poids et taille du/des bébé(s)</li>
                    <li>Photo(s) de profil du/des bébé(s)</li>
                    <li>Adresse électronique de l’utilisateur</li>
                    <li>Numéro de téléphone de l’utilisateur</li>
                </ul>
                <p>Les renseignements personnels collectés sont recueillis au travers de formulaires et grâce à l’interactivité établie entre l’utilisateur et l’application mobile. Des fichiers journaux (logs) et autres méthodes sont également utilisés pour réunir des informations concernant l’utilisation de l’application par l’utilisateur.</p>
                <p>Les renseignements personnels de l’utilisateur sont collectés par le biais d’un formulaire, à savoir le Formulaire d’inscription à l’application.</p>
            </section>
            <section>
                <h2>Finalités</h2>
                <p>Les renseignements ainsi collectés sont utilisés pour les finalités suivantes :</p>
                <ul>
                    <li>Afficher le profil de l’utilisateur sur l’application</li>
                    <li>Afficher le profil du/des bébé(s) sur l’application</li>
                    <li>Afficher le carnet de santé du/des bébé(s) sur l’application</li>
                    <li>Prendre contact avec l’utilisateur via email</li>
                    <li>Prendre contact avec l’utilisateur via numéro de téléphone</li>
                </ul>
            </section>
            <section>
                <h2>Transmission</h2>
                <p>Can Hygiène SPA est seul propriétaire des informations collectées sur l’application mobile.</p>
                <p>En aucun cas il ne sera vendu, loué et partagé les informations personnelles de l’utilisateur avec des personnes ou organismes qui ne sont pas liés aux activités du groupe Ontex.</p>
                <p>En tout état de cause, aucune information à caractère personnel ne sera transférée à quelque personne que ce soit établit à l’étranger, y compris les membres du groupe Ontex, sans l’accord exprès et écrit de l’utilisateur.</p>
                <p>Cependant, Can Hygiène SPA est amené à communiquer les données personnelles des utilisateurs aux destinataires suivants : ses services marketing et web, ainsi qu’aux employés, prestataires et autres fournisseurs tiers et aux prestataires d’emailing dans le cadre de campagnes d’information ou de publicité. Il est important de savoir que dans l’hypothèse où Can Hygiène SPA ferait l’objet d’une fusion ou d’une acquisition de la part d’un tiers ou qu’il serait décidé de céder ou vendre une partie de ses activités, Can Hygiène SPA se réserve le droit, dans chacune de ces circonstances, de transférer ou céder les informations à caractère personnel collectées dans le cadre de cette fusion, acquisition, de ce rachat ou de ce changement de contrôle. Enfin, Can Hygiène SPA se réserve le droit de communiquer les données aux conseils extérieurs (avocats, auditeurs) notamment pour se conformer aux obligations légales et pour défendre ses intérêts.</p>
            </section>
            <section>
                <h2>Droit d’accès/de rectification et d’effacement/de limitation/d’opposition</h2>
                <p> Conformément à la Loi n°18-07, les utilisateurs disposent d’un droit d’accès, de rectification, d’effacement et de limitation de leurs données à caractère personnel traitées par Can Hygiène SPA.</p>
                <p>Les utilisateurs disposent également d’un droit d’opposition au traitement de leurs données personnelles à des fins de prospection.</p>
                <p>Ces droits peuvent être exercés par l’utilisateur, sans avoir à en indiquer le motif et à tout moment, en contactant</p>
                <p>Nom de l’entreprise : Can Hygiène SPA</p>
                <p>Adresse postale : Haouch Sbaat, Nord, Zone industrielle, lot 83B, 16010, BP 36, Rouiba, Algiers | Algeria</p>
                <p>Adresse email : digitaldz@ontexglobal.com</p>
                <p>Téléphone : +213 (0)23 86 41 31</p>
                <p>Par ailleurs, si vous ne souhaitez plus recevoir notre newsletter, vous pouvez simplement vous désinscrire en cliquant sur le lien figurant au bas de chaque email envoyé.</p>
            </section>
            <section>
                <h2>Confidentialité</h2>
                <p>Can Hygiène SPA applique les mesures de sécurité nécessaires afin de préserver la confidentialité et l’intégrité des données à caractère personnel traitées.</p>
                <p>Cependant, Can Hygiène SPA ne peut garantir que ces mesures permettront d’éviter tous risques de divulgation des données à caractère personnel et ne pourra être tenue responsable d’un défaut quelconque de sécurité, notamment en cas de comportement frauduleux d’un tiers ou dans le cas où ce défaut de sécurité serait dû à l’utilisateur.</p>
                <p>Il n’est pas prévu de transfert de données à caractère personnel à destination de pays ne disposant pas d’un niveau de protection adéquat au sens de la législation en vigueur.</p>
                <p>Les renseignements personnels collectés sont conservés dans un environnement sécurisé. Les personnes travaillant pour Can Hygiène SPA sont tenues de respecter la confidentialité des informations de l’utilisateur.</p>
            </section>
            <section>
                <h2>Loi applicable et juridiction compétente</h2>
                <p>La présente politique de confidentialité est administrée par le droit algérien.</p>
                <p>Tout différend relatif à l’accès et/ou l’utilisation de notre application mobile sera soumis aux juridictions adéquates et compétentes.</p>
                <p>Date de dernière mise à jour : 19/03/2021</p>
            </section>
            <footer>
                <p>© 2021 <span>Can Hygiène SPA</span>. Tous droits réservés.</p>
    hoo
            </footer>
        </div>
    )
}

export default PrivacyPolicy
