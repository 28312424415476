import React from 'react';
import style from './Main.module.css';

const Main = () => {
    return (
        <div className={style.main}>
                 <img
                src="/main.png"
                alt="canbebe"
                className={style.babyImage}
            /> 
        </div>
    )
}

export default Main;
