import React from 'react'
import style from "./Header.module.css"

const Header = () => {
    return (
        <div className={`${style.header}`}>
            <img
                src="https://firebasestorage.googleapis.com/v0/b/canbebe-89ff0.appspot.com/o/logo.webp?alt=media&token=6105ddc2-e802-4105-81a1-9f27a1fcfc66"
                alt="canbebe"
            />
            <div className={`${style.app}`}>
                <a href="https://apps.apple.com/app/id1560735124" target="_blank">
                    <img
                        src="/Apple.webp"
                        alt='Apple App'
                    />
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.canbebeapp" target='_black'>
                    <img
                        src="/Google.webp"
                        alt="Google App"
                    />
                </a>

            </div>
        </div>
    )
}

export default Header;